import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater,validURL } from '@/_helpers/funciones';
import { isNull } from 'lodash';

export const planificacionestiba = {
    namespaced: true,
  	state: {
        formReset:0,
        planificacionId:"",
        filtroGrua:'',
        ConditionPlanningId:'',
        ModalityId: '',
        StowageFactor: 0,
        ItineraryId:0,
        idState:0,
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        messageError:'',
        tabIndex:0,
        tabGruaIndex:0,
        collapseGruas:false,
        collapsePlanificacion:false,
        collapsePreliminar:false,
        modalGruas:false,
        modalMaquinas:false,
        modalContainerData:false,
        modalMovimientoRestow:false,
        modalMovimientoDirecto:false,
        modalBugList:false,
        modalPreliminarList:false,
        modalComparacionList:false,
        mytabDataGruas:{
            tablaGruas:[],
        },
        myDataTpVessel: [],
        myDataMaquinas:[],
        myDataItinirary:[],
        myDataTpCondition:[],
        myDataTpOperation:[],
        myDataModality:[],
        myDataWeighingModality:[],
        myDataPlanningDoc:[],
        myDataPreliminarJson:{
            EdiFileName:'',
            PrelimCargoJson:[]
        },
        modalAlertList:false,
        alertInformation:{
            messajeTitle:'',
            ErrorJson:[]
        },
        isHidden: false,
        auxList:false,
        dropItem: 0,
        newUnit:0,
        TpEdiId: '',
        EdiFileId:0,
        EdiFileInfo: null,
        TpVesselId: null,
        TpVesselMasterId: '',
        bulkCarrier: 0, // 1 = buque tipo porta contenedores, 2 = buque tipo bulk carrier
        FgGeneralCargo: false, //true buque de tipo cargar general
        //comparacion
        ComparationLoad: [],
        ComparationDischarge: [],
        ComparationStowageGroup: [],
        showComparationGroup:false,
        FileComparationList:[]
    },
    getters: {
        myDataPlanningDoctable: state => {
            let chart = [];
            if(state.myDataPlanningDoc.length !== 0){
                state.myDataPlanningDoc.sort(function (a, b) {
                    if (a.FgActPlanningDoc < b.FgActPlanningDoc) return 1;
                    else if (a.FgActPlanningDoc > b.FgActPlanningDoc) return -1;
                    else {
                        if (a.PlanningDocDs > b.PlanningDocDs) {
                            return 1;
                        }
                        if (a.PlanningDocDs < b.PlanningDocDs) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myDataPlanningDoc.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActPlanningDoc,
                    _classes: ( item.FgActPlanningDoc ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataItinirarytable: state => {
            let chart = [];
            if(state.myDataItinirary.length !== 0){
                state.myDataItinirary.sort(function (a, b) {
                    if (a.FgActItinerary < b.FgActItinerary) return 1;
                    else if (a.FgActItinerary > b.FgActItinerary) return -1;
                    return 0;
                })
                return state.myDataItinirary.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActItinerary,
                    StatusColor: item.StatusColor ? "color:"+item.StatusColor : '',
                    //_classes: ( item.FgActItinerary ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
        myDataPreliminartable: state => {
            let chart = [];
            if(state.myDataPreliminarJson.PrelimCargoJson.length !== 0){
                return state.myDataPreliminarJson.PrelimCargoJson.map((item,Index) => Object.assign({}, item, {
                    RowNumber: Index+1,
                    ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                    Size: item.Size ? item.Size : '',
                    Class: item.Class ? item.Class : '',
                    Status: item.Status ? item.Status : '',
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                    Weigth: item.Weigth ? item.Weigth : 0,
                    Lenght: item.Lenght ? item.Lenght : 0,
                    Height: item.Height ? item.Height : 0,
                    Seals: item.Seals ? item.Seals : "",
                    Seal1: item.Seal1 ? item.Seal1 : "",
                    Seal2: item.Seal2 ? item.Seal2 : "",
                    Seal3: item.Seal3 ? item.Seal3 : "",
                    Seal4: item.Seal4 ? item.Seal4 : "",
                    Vgm: item.Vgm ? item.Vgm : 0,
                    VgmCertificate: item.VgmCertificate ? item.VgmCertificate : '',
                }));
            }
            return chart;
        },
        mytabDataGruastable: state => {
            let chart = [];
            if(state.mytabDataGruas.tablaGruas.length !== 0){
                state.mytabDataGruas.tablaGruas.sort(function (a, b) {
                    if (a.FgOperational < b.FgOperational) return 1;
                    else if (a.FgOperational > b.FgOperational) return -1;
                    else return 0;
                })
                return state.mytabDataGruas.tablaGruas.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgOperational,
                })); 
            }
            return chart;
        },
        myDataMaquinastable: state => {
            let chart = [];
            if(state.myDataMaquinas.length !== 0){
                return state.myDataMaquinas.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    _classes: ( item.TotalHour === 0 ? 'table-secondary' : '' )
                }));
            }
            return chart;
        },
        //comparacion
        getDataComparationDischarge: state => {
            let chart = [];
            if(state.ComparationStowageGroup.length !== 0){
                let listDicharge = state.ComparationStowageGroup[0].DischargeJson;
                return listDicharge.map((item) => Object.assign({}, item, {
                    change: false,
                }));
            }
            return chart;
        },
        getDataComparationLoad: state => {
            let chart = [];
            if(state.ComparationStowageGroup.length !== 0){
                let listLoad = state.ComparationStowageGroup[0].LoadJson;
                return listLoad.map((item) => Object.assign({}, item, {
                    change: false,
                }));
            }
            return chart;
        },
        getDataComparationPreliminar: state => {
            let chart = [];
            if(state.ComparationStowageGroup.length !== 0){
                let listPreliminar = state.ComparationStowageGroup[0].PreliminarJson;
                return listPreliminar.map((item) => Object.assign({}, item, {
                    change: false,
                }));
            }
            return chart;
        }
    },
    actions: {
        getItininerarylist({commit},{ CompanyBranchId }){
            commit('getItininerarylistRequest');
            service.ejecutar('GET', 'ItineraryStowagePlanning-list', { Filter: 'ALL',CompanyBranchId: (CompanyBranchId === undefined || CompanyBranchId === null) ? '': CompanyBranchId }, '')
            .then(
                response => commit('getItininerarylistSuccess', { response }),
                error => {
                    commit('messageMutation', error)
                    commit('getItininerarylistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        async getPlanificacionlist({commit},{ StowagePlanningId, TpVesselId }){
            commit('getPlanificacionRequest');
            await service.ejecutar('GET', 'TpConditionPlanning-list', { Filter: 'ALL' }, '')
            .then(
                async responseTpCondition => {
                    await service.ejecutar('GET', 'TpOperation-list', { Filter: 'ALL' }, '')
                    .then(
                        async responseTpOperation => {
                            await service.ejecutar('GET', 'Modality-list', { Filter: 'ALL'}, '')
                            .then(
                                async responseModality => {
                                    if(!["", null, undefined].includes(StowagePlanningId)){
                                        await service.ejecutar('GET', 'StowagePlanningDoc-list', { Filter: 'ALL',StowagePlanningId:StowagePlanningId }, '')
                                        .then(
                                            async responsePlanningDoc => {
                                                if(TpVesselId && TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID){
                                                    await service.ejecutar('GET', 'TruckWeighingModality-list', { TpVesselId: TpVesselId})
                                                    .then(
                                                        async responseWeighingModality => {
                                                            await service.ejecutar('GET', 'StowagePlanningTpVessel-list')
                                                            .then(
                                                                async responseTpVessel =>{
                                                                    await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:responsePlanningDoc, responseModality, responseWeighingModality: responseWeighingModality, TpVesselId, responseTpVessel })
                                                                },
                                                                error => {
                                                                    commit('messageMutation', error)
                                                                    commit('getPlanificacionlistFailure', error)
                                                                }
                                                            )
                                                        },
                                                        error => {
                                                            commit('messageMutation', error)
                                                            commit('getPlanificacionlistFailure', error)
                                                        }
                                                    )
                                                }
                                                else if(TpVesselId && TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID){
                                                    await service.ejecutar('GET', 'StowagePlanningTpVessel-list')
                                                    .then(
                                                        async responseTpVessel =>{
                                                            await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:responsePlanningDoc, responseModality, responseWeighingModality: null, TpVesselId, responseTpVessel })
                                                        },
                                                        error => {
                                                            commit('messageMutation', error)
                                                            commit('getPlanificacionlistFailure', error)
                                                        }
                                                    )
                                                }else{
                                                    await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:responsePlanningDoc, responseModality, responseWeighingModality: null, TpVesselId })
                                                }
                                            },
                                            error => {
                                                commit('messageMutation', error)
                                                commit('getPlanificacionlistFailure', error)
                                                //dispatch('alert/error', error, { root: true });
                                            }
                                        );
                                    }else if(TpVesselId && TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID){
                                        await service.ejecutar('GET', 'TruckWeighingModality-list', { TpVesselId: TpVesselId})
                                        .then(
                                            async responseWeighingModality => {
                                                await service.ejecutar('GET', 'StowagePlanningTpVessel-list')
                                                .then(
                                                    async responseTpVessel =>{
                                                        await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:null, responseModality, responseWeighingModality: responseWeighingModality, TpVesselId, responseTpVessel })
                                                    },
                                                    error => {
                                                        commit('messageMutation', error)
                                                        commit('getPlanificacionlistFailure', error)
                                                    }
                                                )
                                            },
                                            error => {
                                                commit('messageMutation', error)
                                                commit('getPlanificacionlistFailure', error)
                                            }
                                        )
                                    } else if(TpVesselId && TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID){
                                        await service.ejecutar('GET', 'StowagePlanningTpVessel-list')
                                        .then(
                                            async responseTpVessel =>{
                                                await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:null, responseModality, responseWeighingModality: null, TpVesselId, responseTpVessel })
                                            },
                                            error => {
                                                commit('messageMutation', error)
                                                commit('getPlanificacionlistFailure', error)
                                            }
                                        )
                                    }else {
                                        await commit('getPlanificacionSuccess', { responseTpCondition,responseTpOperation,responseTpRoute:null, responseModality, responseWeighingModality: null, TpVesselId })
                                    }
                                },
                                error => {
                                    commit('messageMutation', error)
                                    commit('getPlanificacionlistFailure', error)
                                }
                            ); 
                                
                        },
                        error => {
                            commit('messageMutation', error)
                            commit('getPlanificacionlistFailure', error)
                            //dispatch('alert/error', error, { root: true });
                        }
                    );
                },
                error => {
                    commit('messageMutation', error)
                    commit('getPlanificacionlistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        async getTruckWeighingModality({commit, state}, {TpVesselId}) {
            await service.ejecutar('GET', 'TruckWeighingModality-list', { TpVesselId: TpVesselId})
            .then(
                responseWeighingModality => {
                    if (responseWeighingModality?.status == (200 || 201)) {
                        state.myDataWeighingModality =  responseWeighingModality.data.data ?? [];
                    }
                },   
                error => {
                    commit('messageMutation', error)
                    commit('getPlanificacionlistFailure', error)
                }
            )
        },
        sendDatePlanning({commit,dispatch, state},{StowagePlanningId,ConditionPlanningId,OperationId,ItineraryId, ModalityId, TruckWeighingModalityId, FreshWater, Constant, DieselOil, FuelOil, Ballast, StowageFactor, TpVesselId}){
            commit('sendDatePlanningRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'StowagePlanning-insert';
                if(["", null, undefined].includes(StowagePlanningId)){
                    valores = {
                        ItineraryId:ItineraryId,
						OperationId:OperationId,
                        ConditionPlanningId:ConditionPlanningId,
                        ModalityId: ModalityId,
                        TruckWeighingModalityId: TruckWeighingModalityId,
                        FreshWater: FreshWater, 
                        Constant: Constant, 
                        DieselOil: DieselOil, 
                        FuelOil: FuelOil,
                        Ballast: Ballast,
                        StowageFactor: StowageFactor,
                        TpVesselId: TpVesselId,
                    }
                }else{
                    method = 'PUT';
                    link = 'StowagePlanning-update';
                    valores = {
                        ItineraryId:ItineraryId,
						StowagePlanningId:StowagePlanningId,
                        OperationId:OperationId,
                        ConditionPlanningId:ConditionPlanningId,
                        ModalityId: ModalityId,
                        TruckWeighingModalityId: TruckWeighingModalityId,
                        FreshWater: FreshWater, 
                        Constant: Constant, 
                        DieselOil: DieselOil, 
                        FuelOil: FuelOil,
                        Ballast: Ballast,
                        StowageFactor: StowageFactor,
                        TpVesselId: TpVesselId,
                    }
                }
                
                service.ejecutar(method, link, valores, { root: 'StowagePlanningJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							if(information[0].id === ""){
								commit('messageMutation', information[0].Response)
								commit('sendDatePlanningFailure', information[0].Response)
							}else{
								commit('sendDatePlanningSuccess', { information,ConditionPlanningId:ConditionPlanningId });
                                let StowagePlanningId = information[0].StowagePlanningId;
                                if (TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID) {
                                    state.FgGeneralCargo = true;
                                }else{
                                    state.FgGeneralCargo = false;
                                }
                                dispatch('planificacionestiba/getPlanificacionlist', { StowagePlanningId:StowagePlanningId, TpVesselId:TpVesselId } , { root: true });
							}
						}else{
							commit('sendDatePlanningFailure', response.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('sendDatePlanningFailure', error)
					}
				);
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDatePlanningFailure', error)
			}
        },
        sendDateDocPlanning({commit},{StowagePlanningId,StowagePlanningDocId,PlanningDocDs,RoutePlanningDoc,FgActPlanningDoc,filesDoc}){
            commit('sendDateDocPlanningRequest');
			try {
				let method = 'POST';
                let valores = {};
                let link = 'StowagePlanningDoc-insert';
                if(StowagePlanningDocId === 0){
                    valores = {
                        StowagePlanningId:StowagePlanningId,
						PlanningDocDs:PlanningDocDs,
                        RoutePlanningDoc:RoutePlanningDoc
                    }
                }else{
                    method = 'PUT';
                    link = 'StowagePlanningDoc-update';
                    valores = {
                        StowagePlanningDocId:StowagePlanningDocId,
						StowagePlanningId:StowagePlanningId,
                        PlanningDocDs:PlanningDocDs,
                        RoutePlanningDoc:RoutePlanningDoc,
                        Status: FgActPlanningDoc ? 1 : 0,
                    }
                }
                (async () => {
                    try{
                        if(typeof filesDoc !== 'string' && filesDoc !== null && filesDoc !== undefined && filesDoc !== ''){
                            const response = await service.file(link,filesDoc,RoutePlanningDoc);
                            //const response = await service.ejecutar('POST', 'Berth-insert', formData);
                            const info = response.data.data;
                            valores.RoutePlanningDoc = info.files[0].path;
                        }

                        service.ejecutar(method, link, valores, { root: 'StowagePlanningDocJson' })
                        .then(
                            response => {
                                if(response.data.status === 200){
                                    const information = response.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('sendDateDocPlanningFailure', information[0].Response)
                                    }else{
                                        service.ejecutar('GET', 'StowagePlanningDoc-list', { Filter: 'ALL',StowagePlanningId:StowagePlanningId }, '')
                                        .then(
                                            responsePlanningDoc => {
                                                commit('sendDateDocPlanningSuccess', { information,responseTpRoute:responsePlanningDoc });
                                            },
                                            error => {
                                                commit('messageMutation', error)
                                                commit('sendDateDocPlanningFailure', error)
                                                //dispatch('alert/error', error, { root: true });
                                            }
                                        );
                                    }
                                }else{
                                    commit('sendDateDocPlanningFailure', response.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('sendDateDocPlanningFailure', error)
                            }
                        );
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('sendDateDocPlanningFailure', error)
                    }
                    
                })();
			} catch (error) {
				commit('messageMutation', error)
				commit('sendDateDocPlanningFailure', error)
			}
        },
        getStowagePlanningCranelist({commit},{ StowagePlanningId }){
            commit('getStowagePlanningCranelistRequest');
            service.ejecutar('GET', 'StowagePlanningCrane-list-by-planning', { Filter: 'ALL',StowagePlanningId: [null, undefined].includes(StowagePlanningId) ? '': StowagePlanningId }, '')
            .then(
                responseGrua => {
                    commit('getStowagePlanningCranelistSuccess', { responseGrua })
                },
                error => {
                    commit('messageMutation', error)
                    commit('getStowagePlanningCranelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        getStowagePlanningMachinelist({commit},{ StowagePlanningId }){
            commit('getStowagePlanningMachinelistRequest');
            service.ejecutar('GET', 'StowagePlanningMachine-list', { Filter: 'ALL',StowagePlanningId: [null, undefined].includes(StowagePlanningId) ? '': StowagePlanningId }, '')
            .then(
                responseMachine => {    
                    commit('getStowagePlanningMachinelistSuccess', { responseMachine })
                },
                error => {
                    commit('messageMutation', error)
                    commit('getStowagePlanningMachinelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        sendCargoPrelim({commit},{Name,PrelimCargoJson,editDocument,dataDocument,planificacionId}){
            try {
                commit('sendCargoPrelimRequest');
				let method = 'POST';
                let link = '';
                let valores = {};
                let root = '';
                if(editDocument){
                    valores = {
                        Name:Name,
                        PrelimCargoJson:PrelimCargoJson
                    };
                    link = 'StowagePlanningCargoPrelim-insert';
                    root = 'PrelimCargoJson';
                }else{
                    valores = {
                        EdiFileId:dataDocument.EdiFileId,
                        StowagePlanningId:planificacionId,
                        CargoJson:PrelimCargoJson
                    };
                    link = 'StowagePlanningCargoCustomManifest-insert';
                    root = 'CustomManifestJson';
                }
                
                service.ejecutar(method, link, valores, { root: root })
                .then(
                    response => {
                        if(response.data.status === 200){
                            commit('sendCargoPrelimSuccess', { response })
                        }else{
                            commit('sendCargoPrelimFailure', response.data);
                        }
                    },
                    error => {
                        commit('messageMutation', error)
                        commit('sendCargoPrelimFailure', error)
                    }
                )
            } catch (error) {
				commit('messageMutation', error)
				commit('sendCargoPrelimFailure', error)
			}
        },
        getComparationList({commit},{ StowagePlanningId }){
            commit('getComparationListRequest');
            service.ejecutar('GET', 'StowagePlanningGroupEdiFile-list', { StowagePlanningId: [null, undefined].includes(StowagePlanningId) ? '': StowagePlanningId }, '')
            .then(
                responseComparacion => {
                    service.ejecutar('GET', 'DischargePort-by-StowagePlanningId', { StowagePlanningId: [null, undefined].includes(StowagePlanningId) ? '': StowagePlanningId }, '')
                    .then(
                        responseDischarge => {
                            service.ejecutar('GET', 'LoadPort-by-StowagePlanningId', { StowagePlanningId: [null, undefined].includes(StowagePlanningId) ? '': StowagePlanningId }, '')
                            .then(
                                responseLoad => {  
                                    commit('getComparationListSuccess', { responseComparacion,responseDischarge,responseLoad })
                                },
                                error => {
                                    commit('messageMutation', error)
                                    commit('getComparationListFailure', error)
                                    //dispatch('alert/error', error, { root: true });
                                }
                            );
                        },
                        error => {
                            commit('messageMutation', error)
                            commit('getComparationListFailure', error)
                            //dispatch('alert/error', error, { root: true });
                        }
                    );
                },
                error => {
                    commit('messageMutation', error)
                    commit('getComparationListFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postFileCompartionList({commit},{ LoadPortId,DischargePortId,FileJson,resumidoOption,filtrarOption }){
            commit('getComparationListRequest');
            try {
				let method = 'POST';
                let link = 'FileComparison-List';
                let valores = {};
                if(resumidoOption === 1 || resumidoOption === null){
                    valores = {
                        LoadPortId:LoadPortId,
                        DischargePortId:DischargePortId,
                        FileJson:FileJson,
                    }
                }else{
                    link = 'FileComparisonResum';

                    valores = {
                        LoadPortId:LoadPortId,
                        DischargePortId:DischargePortId,
                        FileJson:FileJson,
                        FilterBy: filtrarOption
                    }
                }
                

                service.ejecutar(method, link, valores, { root: 'FileComparisonJson' })
				.then(
					response => {
						if(response.data.status === 200){
							const information = response.data.data;
							commit('postFileCompartionListSuccess', { information });
						}else{
							commit('getComparationListFailure', response.data);
						}
					},
					error => {
						commit('messageMutation', error)
						commit('getComparationListFailure', error)
					}
				);
            } catch (error) {
				commit('messageMutation', error)
                commit('getComparationListFailure', error)
			}
        }
    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
		},
		messageMutation (state, value ) {
            state.messageError = value;
        },
        menuVisibility(state, { check, type }) {
            if(type === 1){
                state.isHidden = check;
                if(check){  
                    state.dropItem = 30;
                }else{
                    state.dropItem = 0;
                }
            }else if(type === 2){
                state.auxList = check;
            }else{
                state.isHidden = check;
                state.auxList = check;
            }
        },
        //Listar TODOS los itinerarios
        getItininerarylistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getItininerarylistSuccess(state, { response }) {
            try{
				if(response.status === 200){
                    state.myDataItinirary = response.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
                state.apiState = ENUM.ERROR; 
            }
        },
        getItininerarylistFailure(state, error) {
            state.apiState = ENUM.ERROR; 
        },

        //Listar toda la data para planificacion
        getPlanificacionRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getPlanificacionSuccess(state, { responseTpCondition,responseTpOperation,responseTpRoute, responseModality, responseWeighingModality, TpVesselId, responseTpVessel }) {
            try{
				if(responseTpCondition.status === 200 && responseTpOperation.status === 200 && responseModality.status){
                    state.myDataTpCondition = responseTpCondition.data.data;
                    state.myDataTpOperation = responseTpOperation.data.data;
                    state.myDataModality = responseModality.data.data;

                    if (responseTpVessel?.status == 200 && TpVesselId === process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID) {
                        state.myDataTpVessel =  responseTpVessel.data.data;
                    }

                    if(responseWeighingModality?.status == 200 && TpVesselId === process.env.VUE_APP_BULK_VESSEL_ID){
                        state.myDataWeighingModality =  responseWeighingModality.data.data;
                        state.myDataTpVessel =  responseTpVessel.data.data;
                    }else{
                        state.apiState = ENUM.LOADED;
                    }       
                    
                    if(responseTpRoute !== null){
                        if(responseTpRoute.status === 200){
                            state.myDataPlanningDoc = responseTpRoute.data.data;
                            state.apiState = ENUM.LOADED;
                        }
                        else
                            state.apiState = ENUM.ERROR;
                    }
                    else
                        state.apiState = ENUM.LOADED;
                    
				}
                else
					state.apiState = ENUM.ERROR; 
				
            }catch(error){
                state.apiState = ENUM.ERROR; 
            }
        },
        getPlanificacionlistFailure(state, error) {
            state.apiState = ENUM.ERROR; 
        },

        //registrar o actualizar condicion de maquinas
		sendDatePlanningRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDatePlanningSuccess (state, { information,ConditionPlanningId }) {
			try{
                state.formReset = 0;
                state.messageError = information[0].Response;
                state.TpVesselId = information[0].TpVesselId;
                state.planificacionId = information[0].StowagePlanningId;
                state.ConditionPlanningId = ConditionPlanningId;
                state.StowageFactor = information[0].StowageFactor;
                const Index = state.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(information[0].ItineraryId);
                if(Index > -1){
                    state.myDataItinirary[Index].Status = information[0].StatusItinerary;
                    state.myDataItinirary = [...state.myDataItinirary];
                }
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				console.error(error);
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDatePlanningFailure(state, error) {
			state.apiStateForm = ENUM.ERROR;
		},

        //registrar o actualizar documentacion de planificacion documentacion
		sendDateDocPlanningRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendDateDocPlanningSuccess (state, { information, responseTpRoute }) {
			try{
                if(responseTpRoute.status === 200){
                    state.myDataPlanningDoc = responseTpRoute.data.data;
                    state.formReset = 1;
                    state.messageError = information[0].Response;
                    state.apiStateForm = ENUM.LOADED;
                }
			}catch(error){
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendDateDocPlanningFailure(state, error) {
			state.apiStateForm = ENUM.ERROR;
		},

        //Listar TODOS las gruas
        getStowagePlanningCranelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getStowagePlanningCranelistSuccess(state, { responseGrua }) {
            try{
				if(responseGrua.status === 200){
                    state.mytabDataGruas.tablaGruas = responseGrua.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
                state.apiState = ENUM.ERROR; 
            }
        },
        getStowagePlanningCranelistFailure(state, error) {
            state.apiState = ENUM.ERROR; 
        },

        //Listar TODAS las maquinas
        getStowagePlanningMachinelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getStowagePlanningMachinelistSuccess(state, { responseMachine }) {
            try{
				if(responseMachine.status === 200){
                    state.myDataMaquinas = responseMachine.data.data;
					state.apiState = ENUM.LOADED;
				}else{
					state.apiState = ENUM.ERROR; 
				}
            }catch(error){
                state.apiState = ENUM.ERROR; 
            }
        },
        getStowagePlanningMachinelistFailure(state, error) {
            state.apiState = ENUM.ERROR; 
        },

        //registrar o actualizar condicion de maquinas
		sendCargoPrelimRequest(state) {
			state.apiStateForm = ENUM.LOADING;
        },
		sendCargoPrelimSuccess (state, { response }) {
			try{
                state.modalPreliminarList = false;
                state.myDataPreliminarJson = response.data.data[0];
                state.collapsePreliminar = true;
                state.newUnit = 0;
				state.apiStateForm = ENUM.LOADED;
			}catch(error){
				state.apiStateForm = ENUM.ERROR;
			}
		},
		sendCargoPrelimFailure(state, error) {
			state.apiStateForm = ENUM.ERROR;
		},
        
        //stowage comparacion
        getComparationListRequest(state) {
            state.apiStateForm = ENUM.LOADING; 
        },
        getComparationListSuccess(state, { responseComparacion,responseDischarge,responseLoad }) {
            try{
				if(responseComparacion.status === 200 && responseDischarge.status === 200 && responseLoad.status === 200){
                    state.ComparationLoad = responseLoad.data.data;
                    state.ComparationDischarge = responseDischarge.data.data;
                    state.ComparationStowageGroup = responseComparacion.data.data;
					state.apiStateForm = ENUM.INIT;
				}else{
					state.apiStateForm = ENUM.ERROR; 
				}
            }catch(error){
                state.apiStateForm = ENUM.ERROR; 
            }
        },
        getComparationListFailure(state, error) {
			state.apiStateForm = ENUM.ERROR;
		},
        //post archivos
        postFileCompartionListSuccess(state,{ information }){
            state.FileComparationList = information;
            state.apiStateForm = ENUM.INIT;
        }
    }
}