import ENUM from './enum';
import service from '@/_services/service';
import { DateFormater ,validURL } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';

export const muelle = {
    namespaced: true,
  	state: {
		apiState: ENUM.INIT,
		apiStateForm: ENUM.INIT,
        id:0,
        myData:[],
        myDataPuerto:[],
        filtroMuelle:'ALL',
        messageError:'',
        filtroPuerto:null,
        modalMuelle:false,
        BerthRoute: '',
    },
    getters: {
        myDatatable: state => {
            let chart = [];
            if(state.myData.length !== 0){
                state.myData.sort(function (a, b) {
                    if (a.FgActBerth < b.FgActBerth) return 1;
                    else if (a.FgActBerth > b.FgActBerth) return -1;
                    else {
                        if (a.BerthName > b.BerthName) {
                            return 1;
                        }
                        if (a.BerthName < b.BerthName) {
                            return -1;
                        }
                        return 0;
                    }
                })
                return state.myData.map((item) => Object.assign({}, item, {
                    RowNumber: item.Nro,
                    UltimoUsuario: item.TransaLogin,
                    Pais: item.IsoCodeAlpha3,
                    Icon: (validURL(item.Icon) || item.Icon === null || item.Icon === '') ? item.Icon : `${DataStorage.getConnection()}${item.Icon.replace('public/','', null, 'i')}`,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    status: item.FgActBerth,
                    Operativo: ( item.FgOper ) ? 'SI' : 'NO',
                    _classes: ( item.FgActBerth ) ? '' : 'table-danger'
                }));
            }
            return chart;
        },
    },
    actions: {
        getPuertolist({commit,dispatch},{opcion, IdPuerto}){
            commit('getPuertolistRequest');
            
            service.ejecutar('GET', 'Port-list',  { Filter: 'ALL', CountryId:'' } , '')
            .then(
                Puertos => {
                    commit('getPuertolistSuccess', { Puertos })
                    if(opcion !== null && opcion !== undefined && opcion == 1){
                        dispatch('muelle/getMuellelist', { filtro: 'ALL', IdPuerto: IdPuerto }, { root: true });
                    }
                },
                error => {
                    commit('messageMutation', error)
                    commit('getPuertolistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },

        getMuellelist({commit},{filtro,IdPuerto}){
            commit('getMuellelistRequest');
            service.ejecutar('GET', 'Berth-list',  { Filter: filtro, PortId:IdPuerto } , '')
            .then(
                Muelles => commit('getMuellelistSuccess', { Muelles }),
                error => {
                    commit('messageMutation', error)
                    commit('getMuellelistFailure', error)
                    //dispatch('alert/error', error, { root: true });
                }
            );
        },
        postregisterMuelle ({ commit }, { Idmuelle,IdPuerto,NbMuelle,CondicionMuelle,LineaAtraque,Calado,Vita,FgOperativo,RutaMuelle,FgActMuelle,Image_original }) {
            commit('postregisterMuelleRequest');
            try {
                let method = 'POST';
                let valores = {};
                let link = 'Berth-insert';
                if(Idmuelle === 0){
                    valores = {
						PortId:IdPuerto,
                        BerthName:NbMuelle,
                        BerthCondition:CondicionMuelle,
                        LineDock:LineaAtraque,
                        Draft:Calado,
                        Vita:Vita,
                        BerthRoute:RutaMuelle,
                        FgOper:FgOperativo ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }else{
                    method = 'PUT';
                    link = 'Berth-update';
                    valores = {
						BerthId:Idmuelle,
                        PortId:IdPuerto,
                        BerthName:NbMuelle,
                        BerthCondition:CondicionMuelle,
                        LineDock:LineaAtraque,
                        Draft:Calado,
                        Vita:Vita,
                        BerthRoute:RutaMuelle,
                        FgOper:FgOperativo ? 1 : 0,
                        Status: FgActMuelle ? 1 : 0,
                        UserId:JSON.parse(localStorage.getItem('user')).UserId
                    }
                }
                (async () => {
                    try {
                        if(typeof RutaMuelle !== 'string' && RutaMuelle !== null && RutaMuelle !== undefined && RutaMuelle !== ''){
                            const response = await service.file(link,RutaMuelle,Image_original);
                            //const response = await service.ejecutar('POST', 'Berth-insert', formData);
                            const info = response.data.data;
                            valores.BerthRoute = info.files[0].path;
                        }

                        service.ejecutar(method, link, valores, { root: 'BerthJson' })
                        .then(
                            dataMuelles => {
                                if(dataMuelles.data.status === 200){
                                    const information = dataMuelles.data.data;
                                    if(information[0].id === ""){
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterMuelleFailure', information[0].Response)
                                    }else{
                                        commit('messageMutation', information[0].Response)
                                        commit('postregisterMuelleSuccess', { IdPuerto })
                                    }
                                }else{
                                    commit('postregisterMuelleFailure', dataMuelles.data);
                                }
                            },
                            error => {
                                commit('messageMutation', error)
                                commit('postregisterMuelleFailure', error)
                                //dispatch('alert/error', error, { root: true });
                            }
                        );
                    } catch (error) {
                        commit('messageMutation', error)
                        commit('postregisterMuelleFailure', error)
                    }
                })();
            } catch (error) {
                commit('messageMutation', error)
                commit('postregisterMuelleFailure', error)
            }
            
        }
    },
    mutations: {
        asignarid (state, id ) {
            state.id = id;
        },
        
        mutationModal (state,  action ) {
            state.modalMuelle = action;
        },
        mutationfiltroMuelle (state,  value ) {
            state.filtroMuelle = value;
        },
        messageMutation (state, value ) {
            state.messageError = value;
		},

        //Listar Puertos
        getPuertolistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getPuertolistSuccess(state, { Puertos }) {
            try{
                if(Puertos.status === 200){
                    state.myDataPuerto = Puertos.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Puertos);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getPuertolistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },

        //Listar muelles
        getMuellelistRequest(state) {
            state.apiState = ENUM.LOADING; 
        },
        getMuellelistSuccess(state, { Muelles }) {
            try{
                if(Muelles.status === 200){
                    state.myData = Muelles.data.data;
                    state.apiState = ENUM.LOADED;
                }else{
                    console.log(Muelles);
                    state.apiState = ENUM.ERROR; 
                }
            }catch(error){
                console.log(error);
                state.apiState = ENUM.ERROR; 
            }
        },
        getMuellelistFailure(state, error) {
            console.log(error);
            state.apiState = ENUM.ERROR; 
        },
        
        //Registrar o actualizar un pais
        postregisterMuelleRequest(state) {
            state.apiStateForm = ENUM.LOADING;
        },
        postregisterMuelleSuccess (state, { IdPuerto }) {
            try{
                state.filtroPuerto = '';
                state.filtroPuerto = IdPuerto;
                state.modalMuelle = false;
                state.apiStateForm = ENUM.LOADED;
            }catch(error){
                console.error(information);
                state.apiStateForm = ENUM.ERROR;
            }
        },
        postregisterMuelleFailure(state, error) {
            console.log(error);
            state.apiStateForm = ENUM.ERROR;
        },
    }
}